import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
import { graphql } from 'gatsby';
import PageImage from '../components/PageImage';
import Talks from '../components/Talks';
export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "pages/images/samindia_cropped.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageImage image={props.data.file} mdxType="PageImage" />
    <h1>{`JavaScript Talks & Interviews`}</h1>
    <p>{`I `}<strong parentName="p">{`love`}</strong>{` teaching people how to code. Reach out to me on `}<a parentName="p" {...{
        "href": "http://twitter.com/samjulien"
      }}>{`Twitter`}</a>{` to invite me to speak somewhere.`}</p>
    <p>{`Here are some of my previous presentations and interviews:`}</p>
    <Talks mdxType="Talks" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      